import { getClientIdFromCookie } from '../google-analytics';
import { ReferrersHostNames, ReferrersSourceParams, QueryParamsEnum } from './enums';
import { initInvitationParams, undefinedInvitationParams } from './constants';
import { disableBodyScroll, enableBodyScroll, getQueryParams } from '../helpers';
import { getInvitationParamsFromStorage, getReferrerHostname, setInvitationParamsToStorage } from './helpers';
import { init as invitationDialogInit, destroy as invitationDialogDestroy } from './invitation-dialog';
var queryParams;
var referrerHostname;
var htmlElements = {};
export function init() {
    referrerHostname = getReferrerHostname();
    queryParams = getQueryParams();
    setHTMLElements();
    setInvitationParamsToStorage(getInvitationParams());
    if (htmlElements.inviteButtons.length) {
        htmlElements.inviteButtons.forEach(function (inviteButton) {
            inviteButton.addEventListener('click', function () { return openInvitationDialog(inviteButton.dataset.shortLinkUrl); });
        });
        window.addEventListener('click', windowClickListener);
    }
}
function setHTMLElements() {
    htmlElements.inviteButtons = document.querySelectorAll('.invite-child-button');
    htmlElements.invitationDialog = document.getElementById('invitation-dialog');
}
function getInvitationParams() {
    var invitationParamsFromStorageRaw = getInvitationParamsFromStorage();
    var invitationParamsFromStorage = invitationParamsFromStorageRaw
        ? JSON.parse(invitationParamsFromStorageRaw)
        : initInvitationParams;
    var clientId = getClientIdFromCookie() || invitationParamsFromStorage.parent_client_id || undefinedInvitationParams.clientId;
    var _a = getCampaignAndSource(invitationParamsFromStorage), source = _a.source, campaign = _a.campaign;
    return {
        parent_client_id: clientId,
        parent_utm_campaign: campaign,
        parent_utm_source: source,
    };
}
function getCampaignAndSource(invitationParamsFromStorage) {
    var campaignFromQueryParams = queryParams[QueryParamsEnum.campaign];
    var sourceFromQueryParams = queryParams[QueryParamsEnum.source];
    var googleClickIdFromQueryParams = queryParams[QueryParamsEnum.googleClickId];
    var isCampaignUndefined = invitationParamsFromStorage.parent_utm_campaign === undefinedInvitationParams.campaign;
    var isCampaignAndSourceExistsInQueryParams = !!campaignFromQueryParams && !!sourceFromQueryParams;
    var isGoogleClickIdExistsInQueryParams = !!googleClickIdFromQueryParams;
    var isReferrerExists = !!document.referrer;
    switch (true) {
        case isCampaignAndSourceExistsInQueryParams:
            return { campaign: campaignFromQueryParams, source: sourceFromQueryParams };
        case !isCampaignUndefined:
            return {
                campaign: invitationParamsFromStorage.parent_utm_campaign,
                source: invitationParamsFromStorage.parent_utm_source,
            };
        case isGoogleClickIdExistsInQueryParams:
            return getCampaignAndSourceByGoogleClickParam();
        case isReferrerExists:
            return getCampaignAndSourceByReferrer();
        default:
            return { campaign: undefinedInvitationParams.campaign, source: undefinedInvitationParams.source };
    }
}
function getCampaignAndSourceByGoogleClickParam() {
    return { campaign: undefinedInvitationParams.campaign, source: 'google' };
}
function getCampaignAndSourceByReferrer() {
    var source;
    switch (true) {
        case referrerHostname.includes(ReferrersHostNames.google):
            source = ReferrersSourceParams.google;
            break;
        case referrerHostname.includes(ReferrersHostNames.bing):
            source = ReferrersSourceParams.bing;
            break;
        case referrerHostname.includes(ReferrersHostNames.yahoo):
            source = ReferrersSourceParams.yahoo;
            break;
        case referrerHostname.includes(ReferrersHostNames.baidu):
            source = ReferrersSourceParams.baidu;
            break;
        case referrerHostname.includes(ReferrersHostNames.ask):
            source = ReferrersSourceParams.ask;
            break;
        case referrerHostname.includes(ReferrersHostNames.duckduckgo):
            source = ReferrersSourceParams.duckduckgo;
            break;
        default:
            source = referrerHostname;
    }
    return { campaign: undefinedInvitationParams.campaign, source: source };
}
function openInvitationDialog(shortLinkUrl) {
    htmlElements.invitationDialog.style.display = 'flex';
    invitationDialogInit(shortLinkUrl);
    disableBodyScroll();
}
function closeInvitationDialog() {
    htmlElements.invitationDialog.style.display = 'none';
    enableBodyScroll();
    invitationDialogDestroy();
}
function windowClickListener(event) {
    if (event.target === htmlElements.invitationDialog) {
        closeInvitationDialog();
    }
}
