import { isBlogPost } from './helpers';
export var init = function () {
    if (!isBlogPost()) {
        return;
    }
    var contentElement = document.getElementById('post-content');
    var progressElement = document.getElementById('header-progress');
    var progressIndicatorElement = document.getElementById('header-progress-indicator');
    progressElement.className = progressElement.className
        .split(' ')
        .filter(function (className) { return className !== 'd-none'; })
        .join(' ');
    window.addEventListener('scroll', function () {
        var unit = contentElement.clientHeight / 100;
        var percentage = window.scrollY / unit;
        var indicatorWidth = percentage > 100 ? '100%' : "".concat(percentage, "%");
        progressIndicatorElement.style.width = indicatorWidth;
    });
};
