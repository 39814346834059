export function init() {
    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' +
                i +
                dl +
                "&gtm_auth=".concat(process.env.GTM_AUTH, "&gtm_preview=").concat(process.env.GTM_ENV, "&gtm_cookies_win=x");
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', "".concat(process.env.GTM_ID));
}
