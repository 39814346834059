var studentSelectorElement;
var studentSelectorToggleButtonElement;
export function init() {
    studentSelectorElement = document.getElementById('students-selector');
    studentSelectorToggleButtonElement = document.getElementById('students-selector-toggle-button');
    studentSelectorToggleButtonElement.addEventListener('click', function () {
        console.log('click');
        toggleList(isListOpened());
    });
    window.addEventListener('click', function (event) {
        var element = event.target;
        if (!studentSelectorElement.contains(element)) {
            closeList();
        }
    });
}
function closeList() {
    toggleList(true);
}
function isListOpened() {
    return studentSelectorElement.getAttribute('aria-expanded') === 'true' || false;
}
function toggleList(isOpened) {
    studentSelectorElement.setAttribute('aria-expanded', !isOpened ? 'true' : 'false');
}
