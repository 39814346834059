var collegeStudentSelectorElement;
var collegeStudentSelectorToggleButtonElement;
export function init() {
    collegeStudentSelectorElement = document.getElementById('college-students-selector');
    collegeStudentSelectorToggleButtonElement = document.getElementById('college-students-selector-toggle-button');
    collegeStudentSelectorToggleButtonElement.addEventListener('click', function () {
        toggleList(isListOpened());
    });
    window.addEventListener('click', function (event) {
        var element = event.target;
        if (!collegeStudentSelectorElement.contains(element)) {
            closeList();
        }
    });
}
function closeList() {
    toggleList(true);
}
function isListOpened() {
    return collegeStudentSelectorElement.getAttribute('aria-expanded') === 'true' || false;
}
function toggleList(isOpened) {
    collegeStudentSelectorElement.setAttribute('aria-expanded', !isOpened ? 'true' : 'false');
}
