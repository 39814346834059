import { scrollToElement } from '../helpers';
import { getHeaderHeight } from '../header';
var goToTestimonialsSectionButtonIds = '[id^="section-main-learners-message-action-button-"]';
var testimonialsSectionId = 'testimonials';
export function init() {
    var goToTestimonialsSectionButtonElements = document.querySelectorAll(goToTestimonialsSectionButtonIds);
    var testimonialsSectionElement = document.getElementById(testimonialsSectionId);
    goToTestimonialsSectionButtonElements.forEach(function (button) {
        button.addEventListener('click', function () {
            scrollToElement(testimonialsSectionElement, getHeaderHeight(), 'auto');
        });
    });
}
