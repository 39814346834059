var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isDesktop } from './helpers';
import SwiperCore, { Lazy, Mousewheel, EffectCoverflow } from 'swiper/core';
SwiperCore.use([Lazy, Mousewheel, EffectCoverflow]);
export function init() {
    if (isDesktop()) {
        initForDesktop();
    }
    else {
        initForTabletAndMobile();
    }
}
function initForDesktop() {
    var swiper = new SwiperCore(getContainerClass(), __assign(__assign({}, getCommonOptions()), { slidesPerView: 2, effect: 'coverflow', direction: 'vertical', mousewheel: true, coverflowEffect: {
            stretch: 150,
            depth: 200,
            rotate: 0,
            modifier: 1,
            slideShadows: false,
        }, on: {
            resize: function () {
                if (!isDesktop()) {
                    swiper.destroy(true, true);
                    initForTabletAndMobile();
                }
            },
        } }));
}
function initForTabletAndMobile() {
    var swiper = new SwiperCore(getContainerClass(), __assign(__assign({}, getCommonOptions()), { slidesPerView: 'auto', direction: 'horizontal', mousewheel: false, breakpoints: {
            320: {
                spaceBetween: 16,
            },
            768: {
                spaceBetween: 30,
            },
        }, on: {
            resize: function () {
                if (isDesktop()) {
                    swiper.destroy(true, true);
                    initForDesktop();
                }
            },
        } }));
}
function getContainerClass() {
    return '.testimonials__slider';
}
function getCommonOptions() {
    return {
        slideActiveClass: 'testimonials__slide-active',
        slideNextClass: 'testimonials__slide-next',
        slidePrevClass: 'testimonials__slide-prev',
        slideToClickedSlide: true,
        loop: true,
        grabCursor: true,
        centeredSlides: true,
        preloadImages: false,
        lazy: {
            loadOnTransitionStart: true,
            loadPrevNext: true,
        },
    };
}
