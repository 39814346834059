var inAppWebviewScreenQueryParam = 'native_app_inapp_webview_screen';
// set this class to elements that should be hidden in in-app webview screen mode
var dNoneClass = 'd-none-in-app-webview-screen';
// set this class to links that should be disabled in in-app webview screen mode
var disabledLinkClass = 'disabled-link-in-app-webview-screen';
// set this class to links that should be as text in in-app webview screen mode
var linkAsTextClass = 'link-as-text-in-app-webview-screen';
// go through html elements and modify them so that only bare page content is left
export function init() {
    document.addEventListener('DOMContentLoaded', function () {
        var urlParams = new URLSearchParams(window.location.search);
        var isReadMode = urlParams.get(inAppWebviewScreenQueryParam) === 'true';
        if (isReadMode) {
            // hide elements with appropriate class
            // disable all links inside element with appropriate class
            // make links with appropriate class to be displayed as text
            var style = document.createElement('style');
            style.textContent = "\n        .".concat(dNoneClass, " { display: none !important; }\n        a.").concat(disabledLinkClass, " { pointer-events: none; color: #69698C; }\n        a.").concat(linkAsTextClass, " { pointer-events: none; color: inherit; text-decoration: none; }\n      ");
            document.head.appendChild(style);
        }
    });
}
export function getIsInAppWebviewScreenModeEnabled() {
    var urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(inAppWebviewScreenQueryParam) === 'true';
}
