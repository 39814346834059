var VIDEO_TITLE = 'How does it work?';
export var sendGenerateLead = function () {
    var _a;
    (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
        event: 'generate_lead',
        page_language: 'en',
    });
};
export var sendVideoStart = function (_a) {
    var _b;
    var visible = _a.visible, currentTime = _a.currentTime, duration = _a.duration, percent = _a.percent, url = _a.url;
    (_b = window.dataLayer) === null || _b === void 0 ? void 0 : _b.push({
        event: 'video_start',
        video_current_time: currentTime,
        video_duration: duration,
        video_percent: percent,
        video_title: VIDEO_TITLE,
        video_url: url,
        visible: visible,
    });
};
export var sendClickLinkApp = function (clickUrl, buttonId) {
    var _a;
    (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
        event: 'click_link_app',
        page_language: 'en',
        click_url: clickUrl,
        button_id: buttonId,
    });
};
export var sendClickLinkTel = function (clickUrl, buttonId) {
    var _a;
    (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
        event: 'click_link_tel',
        page_language: 'en',
        click_url: clickUrl,
        button_id: buttonId,
    });
};
export var sendClickLinkSocial = function (clickUrl, buttonId) {
    var _a;
    (_a = window.dataLayer) === null || _a === void 0 ? void 0 : _a.push({
        event: 'click_link_social',
        page_language: 'en',
        click_url: clickUrl,
        button_id: buttonId,
    });
};
export var sendVideoComplete = function (_a) {
    var _b;
    var visible = _a.visible, currentTime = _a.currentTime, duration = _a.duration, percent = _a.percent, url = _a.url;
    (_b = window.dataLayer) === null || _b === void 0 ? void 0 : _b.push({
        event: 'video_complete',
        video_current_time: currentTime,
        video_duration: duration,
        video_percent: percent,
        video_title: VIDEO_TITLE,
        video_url: url,
        visible: visible,
    });
};
export var sendVideoProgress = function (_a) {
    var _b;
    var visible = _a.visible, currentTime = _a.currentTime, duration = _a.duration, percent = _a.percent, url = _a.url;
    (_b = window.dataLayer) === null || _b === void 0 ? void 0 : _b.push({
        event: 'video_progress',
        video_current_time: currentTime,
        video_duration: duration,
        video_percent: percent,
        video_title: VIDEO_TITLE,
        video_url: url,
        visible: visible,
    });
};
var handleLinkClick = function (eventName, fn) {
    var appLinks = document.querySelectorAll("[data-event-name=\"".concat(eventName, "\"]"));
    Array.from(appLinks).forEach(function (el) {
        el.addEventListener('click', function () {
            var place = el.getAttribute('data-event-place');
            fn(place);
        });
    });
};
export var init = function () {
    handleLinkClick('app-link', function (place) { return sendClickLinkApp(location.href, place); });
    handleLinkClick('tel-link', function (place) { return sendClickLinkTel(location.href, place); });
    handleLinkClick('social-link', function (place) { return sendClickLinkSocial(location.href, place); });
};
