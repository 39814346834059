var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { sendVideoStart, sendVideoProgress, sendVideoComplete } from './business-events';
import { isDesktop } from './helpers';
var videoContainerSelector = '.player-container';
var playerSelector = '.player-element';
var playButtonSelector = '.play-video-button';
var VideoPlayerStateMap = new Map();
export function init() {
    var playerContainers = Array.from(document.querySelectorAll(videoContainerSelector));
    playerContainers.forEach(function (el) {
        var playerElement = el.querySelector(playerSelector);
        var playButtonElement = el.querySelector(playButtonSelector);
        VideoPlayerStateMap.set(playerElement, {
            metadataLoaded: false,
            isSeeking: false,
            paused: false,
        });
        onPlayButtonClickEventListener(playButtonElement, playerElement);
        onVideoEndedEventListener(playerElement, playButtonElement);
        onVideoPauseEventListener(playerElement, playButtonElement);
        onVideoTimeUpdateEventListener(playerElement);
        playerElement.addEventListener('play', function () {
            hidePlayButton(playButtonElement);
        });
        playerElement.addEventListener('seeking', function () {
            var data = VideoPlayerStateMap.get(playerElement);
            data.isSeeking = true;
            VideoPlayerStateMap.set(playerElement, data);
        });
        playerElement.addEventListener('seeked', function () {
            var data = VideoPlayerStateMap.get(playerElement);
            data.isSeeking = false;
            VideoPlayerStateMap.set(playerElement, data);
        });
        playerElement.addEventListener('loadedmetadata', function () {
            var data = VideoPlayerStateMap.get(playerElement);
            data.metadataLoaded = true;
            VideoPlayerStateMap.set(playerElement, data);
        });
    });
}
function getVideoState(playerElement) {
    return __awaiter(this, void 0, void 0, function () {
        var data, currentTime, duration, url, percent, visible;
        return __generator(this, function (_a) {
            data = VideoPlayerStateMap.get(playerElement);
            if (!data.metadataLoaded) {
                return [2 /*return*/, new Promise(function (resolve) {
                        playerElement.addEventListener('loadedmetadata', function () {
                            resolve(getVideoState(playerElement));
                        });
                    })];
            }
            currentTime = playerElement.currentTime, duration = playerElement.duration, url = playerElement.currentSrc;
            percent = Math.round(currentTime) / (duration / 100);
            visible = isInViewport(playerElement);
            return [2 /*return*/, {
                    visible: visible,
                    currentTime: currentTime,
                    duration: duration,
                    url: url,
                    percent: percent,
                }];
        });
    });
}
function showControls(playerElement) {
    playerElement.setAttribute('controls', '');
}
function hideControls(playerElement) {
    playerElement.removeAttribute('controls');
}
function showPlayButton(playButtonElement) {
    playButtonElement.classList.remove('hidden');
}
function hidePlayButton(playButtonElement) {
    playButtonElement.classList.add('hidden');
}
function isInViewport(el) {
    var _a = el.getBoundingClientRect(), top = _a.top, left = _a.left, bottom = _a.bottom, right = _a.right;
    var innerHeight = window.innerHeight, innerWidth = window.innerWidth;
    var _b = document.documentElement, clientHeight = _b.clientHeight, clientWidth = _b.clientWidth;
    var height = innerHeight || clientHeight;
    var width = innerWidth || clientWidth;
    return top >= 0 && left >= 0 && bottom <= height && right <= width;
}
function onPlayButtonClickEventListener(playButtonElement, playerElement) {
    var _this = this;
    playButtonElement.addEventListener('click', function () { return __awaiter(_this, void 0, void 0, function () {
        var data, shouldSendEvent, state;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    data = VideoPlayerStateMap.get(playerElement);
                    shouldSendEvent = !data.paused;
                    data.paused = false;
                    VideoPlayerStateMap.set(playerElement, data);
                    return [4 /*yield*/, playerElement.play()];
                case 1:
                    _a.sent();
                    if (!shouldSendEvent) return [3 /*break*/, 3];
                    return [4 /*yield*/, getVideoState(playerElement)];
                case 2:
                    state = _a.sent();
                    sendVideoStart(state);
                    _a.label = 3;
                case 3:
                    showControls(playerElement);
                    return [2 /*return*/];
            }
        });
    }); });
}
function onVideoEndedEventListener(playerElement, playButtonElement) {
    var _this = this;
    playerElement.addEventListener('ended', function () { return __awaiter(_this, void 0, void 0, function () {
        var data, state;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showPlayButton(playButtonElement);
                    hideControls(playerElement);
                    data = VideoPlayerStateMap.get(playerElement);
                    data.paused = false;
                    VideoPlayerStateMap.set(playerElement, data);
                    return [4 /*yield*/, getVideoState(playerElement)];
                case 1:
                    state = _a.sent();
                    sendVideoComplete(state);
                    return [2 /*return*/];
            }
        });
    }); });
}
function onVideoPauseEventListener(playerElement, playButtonElement) {
    playerElement.addEventListener('pause', function () {
        var data = VideoPlayerStateMap.get(playerElement);
        data.paused = true;
        VideoPlayerStateMap.set(playerElement, data);
        // async call because on seeking this event triggered first and play button appears on the moment
        setTimeout(function () {
            if (!data.isSeeking && isDesktop()) {
                showPlayButton(playButtonElement);
            }
        });
    });
}
function onVideoTimeUpdateEventListener(playerElement) {
    var _this = this;
    var timeGap = 0;
    playerElement.addEventListener('timeupdate', function () { return __awaiter(_this, void 0, void 0, function () {
        var state, percent, gap;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVideoState(playerElement)];
                case 1:
                    state = _a.sent();
                    percent = state.percent;
                    gap = [
                        percent < 10,
                        percent < 25 && percent >= 10,
                        percent < 50 && percent >= 25,
                        percent < 75 && percent >= 50,
                        percent >= 75,
                    ].findIndex(function (result) { return !!result; });
                    if (!gap || gap === timeGap) {
                        return [2 /*return*/];
                    }
                    timeGap = gap;
                    sendVideoProgress(state);
                    return [2 /*return*/];
            }
        });
    }); });
}
