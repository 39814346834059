export function init() {
    // Create a script element for gtag.js
    var script = document.createElement('script');
    script.async = true;
    script.src = "https://www.googletagmanager.com/gtag/js?id=".concat(process.env.GA_MEASUREMENT_ID);
    // Append the script to the document head
    document.head.appendChild(script);
    // Initialize the gtag function
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    // Load the gtag configuration
    script.onload = function () {
        gtag('js', new Date());
        gtag('config', process.env.GA_MEASUREMENT_ID);
    };
}
