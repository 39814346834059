var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import smartlookClient from 'smartlook-client';
var trackingProperties = {};
export function init(initializationDelay) {
    if (initializationDelay === void 0) { initializationDelay = 0; }
    if (process.env.SMARTLOOK_ENABLED === '0') {
        return;
    }
    setTimeout(function () {
        smartlookClient.init(process.env.SMARTLOOK_PROJECT_KEY, {
            region: process.env.SMARTLOOK_REGION,
        });
        setTrackingProperties(getInitTrackingProperties());
        if (!smartlookClient.initialized()) {
            console.error('Warning! Smartlook tracking is not initialized');
        }
    }, initializationDelay);
}
export function enableTracking() {
    if (smartlookClient.initialized()) {
        smartlookClient.record(getEnabledOrDisabledTrackingProperties(true));
    }
}
export function disableTracking() {
    if (smartlookClient.initialized()) {
        smartlookClient.record(getEnabledOrDisabledTrackingProperties(false));
    }
}
export function setTrackingProperties(properties) {
    trackingProperties = __assign(__assign({}, trackingProperties), properties);
}
export function getTrackingProperties() {
    return trackingProperties;
}
function getInitTrackingProperties() {
    return { forms: false, emails: false, ips: false };
}
function getEnabledOrDisabledTrackingProperties(isEnabled) {
    return Object.keys(getTrackingProperties()).reduce(function (acc, key) {
        var _a;
        return __assign(__assign({}, acc), (_a = {}, _a[key] = isEnabled, _a));
    }, {});
}
