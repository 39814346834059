import { scrollToElement, scrollToElementCenter, isForTutorsPage, goToForTutorsPage, isForParentsPage, goToForParentsPage, goToForLearnersPage, } from './helpers';
import { getHeaderHeight } from './header';
import { Storage } from './storage';
var scrollOffset;
var scrollToStorageKey = 'scrollTo';
var subjectsSectionId = 'subjects';
var howItWorksSectionId = 'how-it-works';
var whoCanBecomeTutorSectionId = 'who-can-become-tutor';
var whyIsPeerTutoringBetterSectionId = 'why-us';
export function init() {
    scrollOffset = getScrollOffset();
    var scrollToId = Storage.getItem(scrollToStorageKey);
    initGoToMethods();
    if (scrollToId !== null) {
        Storage.removeItem(scrollToStorageKey);
        scrollToElement(document.getElementById(scrollToId), scrollOffset, 'auto');
    }
}
export function initGoToMethods() {
    window.goToSubjectsSection = function () {
        var subjectsSectionElement = document.getElementById(subjectsSectionId);
        if (subjectsSectionElement) {
            scrollToElementCenter(subjectsSectionElement);
        }
        else {
            Storage.setItem(scrollToStorageKey, subjectsSectionId);
            goToForLearnersPage();
        }
    };
    window.goToHowItWorksSection = function () {
        var howItWorksSectionElement = document.getElementById(howItWorksSectionId);
        if (howItWorksSectionElement) {
            scrollToElement(document.getElementById(howItWorksSectionId), scrollOffset);
        }
        else {
            Storage.setItem(scrollToStorageKey, howItWorksSectionId);
            goToForLearnersPage();
        }
    };
    window.goToWhoCanBecomeTutorSection = function () {
        if (isForTutorsPage()) {
            scrollToElement(document.getElementById(whoCanBecomeTutorSectionId), scrollOffset);
        }
        else {
            Storage.setItem(scrollToStorageKey, whoCanBecomeTutorSectionId);
            goToForTutorsPage();
        }
    };
    window.goToWhyIsPeerTutoringBetterSection = function () {
        if (isForParentsPage()) {
            scrollToElement(document.getElementById(whyIsPeerTutoringBetterSectionId), scrollOffset);
        }
        else {
            Storage.setItem(scrollToStorageKey, whyIsPeerTutoringBetterSectionId);
            goToForParentsPage();
        }
    };
}
function getScrollOffset() {
    return getHeaderHeight() + 10;
}
