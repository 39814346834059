var lazyFonts = [
    { name: 'Underland Alternative', url: '/fonts/underland-alternative/underland-alternative.ttf', weight: '400' },
];
function loadFont(font) {
    var name = font.name, url = font.url, weight = font.weight;
    return new Promise(function (resolve, reject) {
        var loadedFont = new FontFace(name, "url(".concat(url, ")"));
        loadedFont.weight = weight;
        loadedFont
            .load()
            .then(function () { return resolve(loadedFont); })
            .catch(function () { return reject(new Error("Failed to load font: ".concat(name))); });
    });
}
export function loadLazyFonts(delay) {
    if (delay === void 0) { delay = 250; }
    setTimeout(function () {
        var promises = lazyFonts.map(function (font) { return loadFont(font); });
        Promise.all(promises).then(function (fonts) {
            fonts.forEach(function (font) { return document.fonts.add(font); });
            document.body.classList.add('lazy-fonts-loaded');
        });
    }, delay);
}
