import { disableScroll, isInfoPages, enableScroll, getWindowWidth, isBlogPages, isDesktop, disableBodyScroll, enableBodyScroll, isCollegeLearnersPage, isBecomeCollegeTutorPage, isMainPage, isParentPage, } from './helpers';
import { getTabletLandscapeMinWidth } from './layout';
var headerElement;
var headerMenuElement;
var headerLogoImageElement;
var headerLogoImageAlternativeElement;
var headerMenuButton;
var headerMenuListWrap;
var classes = {
    stuckHeader: 'stuck',
    alternativeMenu: 'alternative',
    menuOpen: 'open',
    menuButtonOpen: 'header-menu__button--open',
    menuListWrapOpen: 'header-menu__list-wrap--open',
    headerMenuOpen: 'menu-open',
    hiddenLogo: 'hidden-image',
};
export function init() {
    headerElement = document.getElementById('header');
    headerMenuElement = document.getElementById('header-menu');
    headerLogoImageElement = document.getElementById('header-logo-image');
    headerLogoImageAlternativeElement = document.getElementById('header-logo-image-alternative');
    headerMenuButton = document.getElementById('header-menu-button');
    headerMenuListWrap = document.getElementById('header-menu-list-wrap');
    setOnResizeEvent();
    setOnScrollEvent();
    setOnHeaderButtonClickEvent();
    setOnHeaderLinksClickEvent();
    setHeaderClasses();
}
export function getHeaderHeight() {
    return headerElement.offsetHeight;
}
export function shouldApplyAlternativeHeaderClasses() {
    return isBlogPages() || isInfoPages();
}
export function shouldBeTransparentOnTop() {
    return isCollegeLearnersPage() || isBecomeCollegeTutorPage() || isMainPage() || isParentPage();
}
function setHeaderClasses() {
    var pageScrolled = window.scrollY > 0;
    var addStuckClass = pageScrolled || shouldApplyAlternativeHeaderClasses();
    if (addStuckClass) {
        headerElement.classList.add(classes.stuckHeader);
    }
    else {
        headerElement.classList.remove(classes.stuckHeader);
    }
    var showAlternativeMenu = pageScrolled || shouldApplyAlternativeHeaderClasses() || shouldBeTransparentOnTop();
    if (showAlternativeMenu) {
        headerMenuElement.classList.add(classes.alternativeMenu);
    }
    else {
        headerMenuElement.classList.remove(classes.alternativeMenu);
    }
    var showAlternativeLogo = (pageScrolled || shouldApplyAlternativeHeaderClasses() || shouldBeTransparentOnTop()) && !isHeaderMenuExpanded();
    if (showAlternativeLogo) {
        headerLogoImageElement.classList.add(classes.hiddenLogo);
        headerLogoImageAlternativeElement.classList.remove(classes.hiddenLogo);
    }
    else {
        headerLogoImageElement.classList.remove(classes.hiddenLogo);
        headerLogoImageAlternativeElement.classList.add(classes.hiddenLogo);
    }
    if (pageScrolled) {
        addAlternativeHeaderBackground();
    }
    else {
        removeAlternativeHeaderBackground();
    }
}
function addAlternativeHeaderBackground() {
    var headerBackgroundOpacity = Math.floor((window.scrollY / getFullOpacityOnPx()) * 100);
    headerElement.style.background = "rgba(255, 255, 255, ".concat(headerBackgroundOpacity, "%)");
}
function removeAlternativeHeaderBackground() {
    headerElement.style.background = null;
}
function setOnHeaderButtonClickEvent() {
    headerMenuButton.addEventListener('click', onHeaderButtonClickEventListener);
}
function onHeaderButtonClickEventListener() {
    var isExpanded = isHeaderMenuExpanded();
    if (!isExpanded) {
        disableAllScrolls();
    }
    else {
        enableAllScrolls();
    }
    headerMenuButton.setAttribute('aria-expanded', !isExpanded ? 'true' : 'false');
    headerMenuElement.classList.toggle(classes.menuOpen);
    headerMenuButton.classList.toggle(classes.menuButtonOpen);
    headerMenuListWrap.classList.toggle(classes.menuListWrapOpen);
    headerElement.classList.toggle(classes.headerMenuOpen);
    setHeaderClasses();
}
function setOnResizeEvent() {
    window.addEventListener('resize', function () {
        if (getWindowWidth() >= getTabletLandscapeMinWidth()) {
            headerMenuButton.setAttribute('aria-expanded', 'false');
            headerMenuElement.classList.remove(classes.menuOpen);
            headerMenuButton.classList.remove(classes.menuButtonOpen);
            headerMenuListWrap.classList.remove(classes.menuListWrapOpen);
            headerElement.classList.remove(classes.headerMenuOpen);
        }
        setHeaderClasses();
    });
}
function setOnHeaderLinksClickEvent() {
    var anchors = document.getElementsByClassName('header-menu__link');
    for (var i = 0; i < anchors.length; i++) {
        anchors[i].addEventListener('click', function () {
            if (!isDesktop()) {
                onHeaderButtonClickEventListener();
            }
        }, false);
    }
}
function setOnScrollEvent() {
    window.addEventListener('scroll', function () {
        setHeaderClasses();
    });
}
function getFullOpacityOnPx() {
    return 60;
}
function isHeaderMenuExpanded() {
    return headerMenuButton.getAttribute('aria-expanded') === 'true' || false;
}
function disableAllScrolls() {
    window.addEventListener('touchmove', touchmoveForWindowListener);
    headerMenuListWrap.addEventListener('touchmove', touchmoveForHeaderMenuListWrapListener);
    disableBodyScroll();
    disableScroll();
}
function enableAllScrolls() {
    window.removeEventListener('touchmove', touchmoveForWindowListener);
    headerMenuListWrap.removeEventListener('touchmove', touchmoveForHeaderMenuListWrapListener);
    enableBodyScroll();
    enableScroll();
}
function touchmoveForWindowListener(event) {
    if (!headerMenuListWrap.contains(event.target)) {
        event.preventDefault();
    }
}
function touchmoveForHeaderMenuListWrapListener(event) {
    var top = this.scrollTop, totalScroll = this.scrollHeight, currentScroll = top + this.offsetHeight;
    if (top === 0 && currentScroll === totalScroll) {
        event.preventDefault();
    }
    else if (top === 0) {
        this.scrollTop = 1;
    }
    else if (currentScroll === totalScroll) {
        this.scrollTop = top - 1;
    }
}
