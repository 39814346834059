import { init as initVideoPlayer } from './video-player';
import { init as initTestimonialsSlider } from './testimonials-slider';
import { init as initFooter } from './footer';
import { init as initHeader } from './header';
import { init as initContactUsForm } from './contact-form';
import { init as initShareLinks } from './share-links';
import { init as initBusinessEvents } from './business-events';
import { init as initBlogPost } from './blog-post';
import { init as initInvitation } from './invitation';
import { init as initLanguageSelector } from './language-selector';
import { init as initLearner } from './learner';
import { init as initCookies } from './cookie-consent';
import { init as volunteerForUkraine } from './volunteer-for-ukraine';
import { init as initZenDeskWidget } from './zen-desk-widget';
import { init as initSmartlook } from './smartlook';
import { init as initGTM } from './gtm';
import { init as initGtag } from './gtag';
import { init as initInAppWebviewSreenMode, getIsInAppWebviewScreenModeEnabled } from './in-app-webview-screen-mode';
import { init as studentsSelector } from './students-selector';
import { init as collegeStudentsSelector } from './college-students-selector';
import { init as initAnimations } from './animations';
import { loadLazyFonts } from './lazy-fonts-loader';
init();
function init() {
    var isInAppWebviewScreenModeEnabled = getIsInAppWebviewScreenModeEnabled();
    if (!isInAppWebviewScreenModeEnabled) {
        initSmartlook(3000);
        initCookies();
        initBlogPost();
        initHeader();
        initFooter();
        initShareLinks();
        initContactUsForm();
        initInvitation();
        initLanguageSelector();
        initLearner();
        volunteerForUkraine();
        setLoadEvent();
        studentsSelector();
        collegeStudentsSelector();
        initGTM();
        initGtag();
        initAnimations();
    }
    initInAppWebviewSreenMode();
}
function setLoadEvent() {
    window.addEventListener('load', function () {
        loadLazyFonts();
        initVideoPlayer();
        initTestimonialsSlider();
        initBusinessEvents();
        initZenDeskWidget();
    });
}
