import { scrollToElement } from '../helpers';
import { getHeaderHeight } from '../header';
var readMoreButtonId = 'read-more-button';
var roadToEscapeSectionId = 'road-to-escape';
export function init() {
    var readMoreButtonElement = document.getElementById(readMoreButtonId);
    var roadToEscapeSectionElement = document.getElementById(roadToEscapeSectionId);
    if (readMoreButtonElement) {
        readMoreButtonElement.addEventListener('click', function () {
            scrollToElement(roadToEscapeSectionElement, getHeaderHeight(), 'smooth');
        });
    }
}
