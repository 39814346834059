import { detectLocalizationPrefix, getSelectedLanguage } from './helpers';
var languageSelectorElement;
var languageSelectorToggleButtonElement;
var languageSelectorToggleButtonTextElement;
var languageSelectorListElement;
var selectedElement;
export function init() {
    languageSelectorElement = document.getElementById('language-selector');
    languageSelectorToggleButtonElement = document.getElementById('language-selector-toggle-button');
    languageSelectorToggleButtonTextElement = document.getElementById('language-selector-toggle-button-text');
    languageSelectorListElement = document.getElementById('language-selector-list');
    var selectedLang = getSelectedLanguage();
    selectedElement = languageSelectorListElement.querySelectorAll("[lang=\"".concat(selectedLang, "\"]"))[0];
    if (selectedElement) {
        selectedElement.setAttribute('aria-selected', 'true');
        languageSelectorToggleButtonTextElement.innerHTML = selectedElement.getAttribute('data-code');
    }
    languageSelectorToggleButtonElement.addEventListener('click', function () {
        toggleList(isListOpened());
    });
    languageSelectorListElement.addEventListener('click', function (event) {
        var element = event.target;
        var lang = element.getAttribute('lang');
        var pageUrl = element.getAttribute('value');
        if (!pageUrl || !lang) {
            return;
        }
        var localizationPrefix = detectLocalizationPrefix(pageUrl);
        if (localizationPrefix) {
            pageUrl = pageUrl.replace("/".concat(localizationPrefix, "/"), '/');
        }
        if (lang !== 'en') {
            pageUrl = "/".concat(lang).concat(pageUrl);
        }
        window.location.href = pageUrl;
    });
    window.addEventListener('click', function (event) {
        var element = event.target;
        if (!languageSelectorElement.contains(element)) {
            closeList();
        }
    });
}
function closeList() {
    toggleList(true);
}
function isListOpened() {
    return languageSelectorElement.getAttribute('aria-expanded') === 'true' || false;
}
function toggleList(isOpened) {
    languageSelectorElement.setAttribute('aria-expanded', !isOpened ? 'true' : 'false');
}
