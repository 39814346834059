import { enableTracking as enableSmartlookTracking, disableTracking as disableSmartlookTracking } from './smartlook';
import { getCookie, setCookie } from 'typescript-cookie';
var cookieStorageKey = "tutorpeers-".concat(process.env.NODE_ENV, "-cookie-consent-confirmed");
// Note: chrome limits days for 400.
var cookieMaxExpireDays = 365 * 10;
export function init() {
    var consentContainer = document.querySelector('.cookie-consent');
    var cookieStorageValue = getCookie(cookieStorageKey);
    if (!cookieStorageValue) {
        consentContainer.classList.add('visible');
        disableSmartlookTracking();
        listenAcceptButtonClickEvent(consentContainer);
    }
    else {
        enableSmartlookTracking();
    }
}
function listenAcceptButtonClickEvent(consentContainerElement) {
    var acceptButton = document.querySelector('.cookie-consent__accept');
    acceptButton.addEventListener('click', function () {
        setCookie(cookieStorageKey, true, { expires: cookieMaxExpireDays, path: '/' });
        consentContainerElement.remove();
        enableSmartlookTracking();
    }, { once: true });
}
