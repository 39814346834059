export var init = function () {
    facebookInit();
    twitterInit();
};
var twitterInit = function () {
    var button = document.getElementById('blog-post-share-twitter');
    if (!button) {
        return;
    }
    var text = button.getAttribute('data-text');
    button.addEventListener('click', function () {
        var pageUrl = location.href;
        button.blur();
        showPopup("https://twitter.com/intent/tweet?text=".concat(text, "&related=&url=").concat(pageUrl));
    });
};
var facebookInit = function () {
    var button = document.getElementById('blog-post-share-facebook');
    if (!button) {
        return;
    }
    button.addEventListener('click', function () {
        var pageUrl = location.href;
        button.blur();
        showPopup("https://www.facebook.com/sharer/sharer.php?u=".concat(pageUrl));
    });
};
var showPopup = function (url) {
    var width = 580;
    var height = 325;
    var top = screen.height / 2 - height / 2;
    var left = screen.width / 2 - width / 2;
    var features = "top=".concat(top, ",left=").concat(left, ",toolbar=0,status=0,width=").concat(width, ",height=").concat(height);
    window.open(url, 'sharer', features);
};
