var Storage = /** @class */ (function () {
    function Storage() {
    }
    Storage.setItem = function (key, value) {
        localStorage.setItem(key, value);
    };
    Storage.getItem = function (key) {
        return localStorage.getItem(key);
    };
    Storage.removeItem = function (key) {
        localStorage.removeItem(key);
    };
    Storage.clear = function () {
        localStorage.clear();
    };
    return Storage;
}());
export { Storage };
